export const auth = (to, from, next) => {
  const token = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`);
  const isLoggedIn = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`);

  const publicPages = [
    "/login",
    "/admin/login",
    "/forgot-password",
    "/verify-email/" + to.params.token,
    "/reset-password/" + to.params.token,
    "/pages/error-419",
    "/success",
  ];
  const authRequired = !publicPages.includes(to.path);

  // console.log(authRequired, to.path, from.path)
  // if (!authRequired && !isLoggedIn) {
  //   next()
  // }

  if (!authRequired) {
    return next()
  }
  if (!isLoggedIn && to.path !== '/login' && authRequired) {
    next('/admin/login')
  }

  if (!authRequired && isLoggedIn && token) {
    const user = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}UserInfo`));
    if (user.userType === "admin") {
      return next("/admin/dashboard");
    }
  }

  if (authRequired && !isLoggedIn && !token) {
    if (from.path !== "/admin/login") {
      return next("/admin/login");
    }
    else {
      next()
    }
  }

  if (to.path === "/login" && isLoggedIn) {
    return next("/admin/dashboard");
  }
  // console.log(to);



  return next();
};
