/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

//import axios from "@/axios.js"
import jwt from "../../http/requests/auth/jwt/index.js";

export default {
  // addItem({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     axios.post("/api/data-list/products/", {item: item})
  //       .then((response) => {
  //         commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  fetchUsers({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt.fetchUsers(data)

        .then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err)
        });
    })
  },
  // fetchUsers({ commit }) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("/api/user-management/users")
  //       .then((response) => {
  //         commit('SET_USERS', response.data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  fetchUser(context, userId) {
    return new Promise((resolve, reject) => {
      jwt.fetchUser(userId)

        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  addNewUser({ commit }, user) {
    return new Promise((resolve, reject) => {
      jwt.addNewUser(user)

        .then((response) => {
          resolve(response)
        }).catch((e) => { reject(e) })
    })
  },
  updateAdminProfile({ commit }, user) {
    return new Promise((resolve, reject) => {
      jwt.updateAdminProfile(user)

        .then((response) => {
          commit("UPDATE_USER_INFO", response.data.data, {
            root: true,
          });
          resolve(response)
        }).catch((e) => { reject(e) })
    })
  },
  updateUserDetails({ commit }, user) {
    return new Promise((resolve, reject) => {
      jwt.updateUserDetails(user)

        .then((response) => {
          resolve(response)
        }).catch((e) => { reject(e) })
    })
  },
  removeUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt.removeUser(id)
        .then((response) => {
          // commit('REMOVE_RECORD', id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
