import state from "./moduleCarDealerState.js";
import mutations from "./moduleCarDealerMutations.js";
import actions from "./moduleCarDealerActions.js";
import getters from "./moduleCarDealerGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
