/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    SET_MARKETS(state, markets) {
        state.markets = markets
    },
    REMOVE_RECORD(state, itemId) {
        const userIndex = state.markets.findIndex((u) => u.id == itemId)
        state.markets.splice(userIndex, 1)
    },
}
