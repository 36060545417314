import jwt from "../../http/requests/auth/jwt/index.js";

export default {
  setEmailSearchQuery({ commit }, query) {
    commit("SET_EMAIL_SEARCH_QUERY", query)
  },

  // Fetch emails
  fetchEmails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt.fetchEmails(data)
        .then((response) => {
          // commit("SET_MAILS", response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchEmailDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt.fetchEmailDetails(id)

        .then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
    })
  },
  updateEmailDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt.updateEmailDetails(data)

        .then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
    })
  },



  // Fetch Tags
  fetchTags({ commit }) {
    return new Promise((resolve, reject) => {
      jwt.fetchTags(data)

        .then((response) => {
          commit("SET_TAGS", response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Fetch Email Meta
  fetchMeta({ commit }) {
    return new Promise((resolve, reject) => {
      jwt.fetchMeta(data)

        .then((response) => {
          commit("SET_META", response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Move mails to another folder
  moveMailsTo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.moveMailsTo(payload)

        .then((response) => {
          commit("MOVE_MAILS_TO", payload)
          commit("UPDATE_UNREAD_META_ON_MOVE", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Update Mails label
  updateLabels({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.updateLabels(payload)

        .then((response) => {
          commit("UPDATE_LABELS", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  setLabels({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.setLabels(payload)

        .then((response) => {
          commit("SET_LABELS", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Set mails flag unread to true
  setUnread({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.setUnread(payload)

        .then((response) => {
          commit("SET_UNREAD", payload)

          // Remove this if you are getting meta like us
          // Use your own method to update email meta if you are fetching email meta
          commit("UPDATE_UNREAD_META", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  // Toggle isStarred flag in mail
  toggleIsMailStarred({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt.toggleIsMailStarred(payload)
      
        .then((response) => {
          commit("TOGGLE_IS_MAIL_STARRED", payload)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
