/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// import axios from "@/axios.js"
import jwt from "../../http/requests/auth/jwt/index.js";

export default {
    // addItem({ commit }, item) {
    //   return new Promise((resolve, reject) => {
    //     axios.post("/api/data-list/products/", {item: item})
    //       .then((response) => {
    //         commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
    //         resolve(response)
    //       })
    //       .catch((error) => { reject(error) })
    //   })
    // },
    fetchMarkets({ commit }, data) {
        return new Promise((resolve, reject) => {
            jwt.fetchMarkets(data)
                .then((res) => {
                    resolve(res);
                }).catch((err) => {
                    reject(err)
                });
        })
    },
    // fetchUsers({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     axios.get("/api/user-management/users")
    //       .then((response) => {
    //         commit('SET_USERS', response.data)
    //         resolve(response)
    //       })
    //       .catch((error) => { reject(error) })
    //   })
    // },
    fetchMarket(context, marketId) {
        return new Promise((resolve, reject) => {
            jwt.fetchMarket(marketId)

                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    updateMarketDetails({ commit }, market) {
        return new Promise((resolve, reject) => {
            jwt.updateMarketDetails(market)

                .then((response) => {
                    resolve(response)
                }).catch((e) => { reject(e) })
        })
    },
    addNewMarket({ commit }, market) {
        return new Promise((resolve, reject) => {
            jwt.addNewMarket(market)

                .then((response) => {
                    resolve(response)
                }).catch((e) => { reject(e) })
        })
    },
    // updateUserDetails({ commit }, user) {
    //     return new Promise((resolve, reject) => {
    //         axios.put(`admin/user-management/userDetail/${user._id}`, user)
    //             .then((response) => {
    //                 resolve(response)
    //             }).catch((e) => { reject(e) })
    //     })
    // },
    removeMarket({ commit }, id) {
        return new Promise((resolve, reject) => {
            jwt.removeMarket(id)
           
                .then((response) => {
                    // commit('REMOVE_RECORD', id)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    }
}
