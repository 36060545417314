// import axios from '@/axios';
import jwt from "../../http/requests/auth/jwt/index.js";

export default {
    fetchPages({ commit }, data) {
        return new Promise((resolve, reject) => {
            jwt.fetchPages(data)

                .then((res) => {
                    resolve(res);
                }).catch((err) => {
                    reject(err)
                });
        })
    },
    fetchPageDetails({ commit }, id) {
        return new Promise((resolve, reject) => {
            jwt.fetchPageDetails(id)

                .then((res) => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                })
        })
    },
    savePagesSettings({ commit }, data) {
        return new Promise((resolve, reject) => {
            jwt.savePagesSettings(data)

                .then((res) => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                })
        })
    },
    getPagesSettings({ commit }, slug) {
        return new Promise((resolve, reject) => {
            jwt.getPagesSettings(slug)

                .then((res) => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                })
        })
    },
    updatePageDetails({ commit }, data) {
        return new Promise((resolve, reject) => {
            jwt.updatePageDetails(data)

                .then((res) => {
                    resolve(res);
                }).catch(err => {
                    reject(err)
                })
        })
    },

    fetchPageDetailsBySlug({ commit }, slug) {
        return new Promise((resolve, reject) => {
            jwt.fetchPageDetailsBySlug(slug)
           
            .then((res) => {
                resolve(res);
            }).catch(err => {
                reject(err)
            })
        })
    },

}
