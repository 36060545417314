import jwt from "../../http/requests/auth/jwt/index.js";

export default {
  fetchCarDealers({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchCarDealers(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCarDealerDetail(context, carDealerId) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchCarDealer(carDealerId)

        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateCarDealerDetails({ commit }, CarDealer) {
    return new Promise((resolve, reject) => {
      jwt
        .updateCarDealerDetails(CarDealer)

        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  addNewCarDealer({ commit }, CarDealer) {
    return new Promise((resolve, reject) => {
      jwt
        .addNewCarDealer(CarDealer)

        .then(response => {
          resolve(response);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  removeCarDealer({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .removeCarDealer(id)

        .then(response => {
          // commit('REMOVE_RECORD', id)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  //add car of a dealer
  addNewCar({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addNewCar(data)

        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDealerCarList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchDealerCars(data)

        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  deleteDealerCar({ commit }, carId) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteDealerCar(carId)

        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateDealerCar({ commit }, carData) {
    return new Promise((resolve, reject) => {
      jwt
        .updateDealerCar(carData)

        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  //for car attributes
  carMakerList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarMakerList(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  carModels({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarModels(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  carYearList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarYearList(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  carBadgeList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarBadgeList(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  carSeriesList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarSeriesList(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  carBodyTypeList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarBodyType(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  carTransmissionList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarTransmission(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  carEngineCapacity({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarEngineCapacity(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  carCylinders({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarCylinders(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  statesList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getStatesList(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  priceRange({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .getPricesRange()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  kilometerRange({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .getKilometerRange()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};
