import axios from "../../../axios/index.js"
import store from "../../../../store/store.js"

import router from "@/router";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

// Token Refresh
let isAlreadyFetchingAccessToken = false
let subscribers = []
const apiAdminUrl = "admin/"
const apiUrl = process.env.VUE_APP_BASE_API_URL
const appLoading = document.getElementById("loading-bg");
const logoLoader = document.getElementById("logo-loader");

function onAccessTokenFetched(refreshData) {
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`,
    refreshData.accessToken
  );

  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`,
    refreshData.refreshToken
  );

  Vue.$cookies.set(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`,
    refreshData.refreshToken,
    refreshData.refreshTokenExpiresIn,
    "",
    "",
    true
  );
  localStorage.setItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`,
    "true"
  );
  subscribers = subscribers.filter(callback => callback(refreshData.accessToken))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

export default {
  init() {
    axios.interceptors.request.use(
      config => {
        appLoading.style.display = "inline-block";
        logoLoader.style.display = "none";
        const token = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
        );
        if (token) {
          config.headers.Authorization = token;
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function(response) {
        if (appLoading) {
          appLoading.style.display = "none";
          logoLoader.style.display = "inline-block";
        }

        return response;
      },
      function(error) {
        if (appLoading) {
          appLoading.style.display = "none";
          logoLoader.style.display = "inline-block";
        }

        const { config, response } = error;

        const originalRequest = config;

        if (config.url.includes("login") && response.status === 401) {
          return Promise.reject(error);
        }

        if (config.url.includes("refreshToken") && response.status === 401) {
          router.push("/admin/login");
        }

        if (response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            store
              .dispatch("auth/fetchAccessToken")
              .then(refreshTokenResponse => {
                isAlreadyFetchingAccessToken = false;
                onAccessTokenFetched(refreshTokenResponse.data.data);
              });
          }
          const retryOriginalRequest = new Promise(resolve => {
            addSubscriber(access_token => {
              originalRequest.headers.Authorization = access_token;
              console.log(originalRequest.headers);
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }

        return Promise.reject(error);
      }
    );
  },

  //admin actkions
  login(email, pwd) {
    return axios.post(apiAdminUrl + "login", { email: email, password: pwd });
  },
  refreshToken() {
    return axios.post("user/refreshToken", {
      refreshToken: localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`
      )
    });
  },
  checkTokenExpiry(token) {
    return axios.post("checkTokenExpiry", { token });
  },
  resetPassword(password, token) {
    return axios.post("user/resetPassword", {
      password: password,
      token: token
    });
  },
  forgotPasswordAdmin(email) {
    return axios.post("user/forgotPassword", { email: email });
  },
  changePassword(currentPassword, newPassword) {
    return axios.post("user/changePassword", {
      oldPassword: currentPassword,
      newPassword: newPassword
    });
  },

  restore(subscriptionId) {
    return axios.post("admin/subscribers/restore", {
      subscriptionId: subscriptionId
    });
  },
  userSubscripiton(userId, page, limit, search, sort, dir) {
    return axios.get(
      "admin/subscribers/getMySubscription?userId=" +
        userId +
        "&page=" +
        page +
        "&limit=" +
        limit +
        "&search=" +
        search +
        "&sort=" +
        sort +
        "&dir=" +
        dir
    );
  },

  subscrptionjsonview(data) {
    return axios.post("/admin/subscribers/jsonView", data);
  },

  fetchVehicles(data) {
    return axios.get(
      "admin/vehicle/getUserCarList?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&filter=" +
        data.filter +
        "&status=" +
        data.status
    );
  },
  fetchSubscribers(data) {
    return axios.get(
      "admin/subscribers/getAllSubscriber?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchVehiclesForCsv(data) {
    return axios.get(
      "admin/vehicle/getUserCarListCsv?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
        +
        "&filter=" +
        data.filter +
        "&status=" +
        data.status
    );
  },
  fetchAuctionsList(data) {
    return axios.get(
      "admin/auction?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir +
        "&filter=" +
        data.filter +
        "&status=" +
        data.status
    );
  },
  auctionDetail(id) {
    return axios.get("admin/auction/" + id);
  },
  carDetail(id) {
    return axios.get("admin/vehicle/" + id);
  },
  deleteCar(id) {
    return axios.delete("admin/vehicle/deleteUserCarById/" + id);
  },
  acceptRejectNewCarListing(data) {
    return axios.post("admin/vehicle/confirmCarAdmin/" + data.id, {isRejected: data.isRejected});
  },
  getCarBiddingHistory(data) {
    return axios.post("car/auctions/bidDetails/" + data.id + "?page=" +
    data.page +
    "&limit=" +
    data.limit);
  },
  changeBidAmount(data) {
    return axios.post("car/auctions/bidAmountChange/" + data.id, {newBidAmount: data.newBidAmount, auctionId: data.auctionId});
  },
  checkPassword(data) {
    return axios.post("car/auctions/checkPassword", { password: data.password });
  },
  fetchCardDetails() {
    return axios.get(apiAdminUrl + "dashboard/getCardDetails");
  },
  fetchChartsDetails() {
    return axios.get("admin/dashboard/getChartDetails");
  },
  getSettingList(data) {
    return axios.get(
      "admin/settings/getSettingsList?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  upateSetting(data) {
    return axios.put("admin/settings/setting/" + data._id, data);
  },
  fetchAdmins(data) {
    return axios.get(
      "/api/v1/admins/adminDetails?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchAdminDetails(id) {
    return axios.get("/api/v1/admins/adminDetails/" + id);
  },
  updateAdminDetails(data) {
    return axios.post(
      "/api/v1/admins/adminDetails/update/" + data.id,
      data.info
    );
  },
  saveAdmin(data) {
    return axios.post("/api/v1/register", data.info);
  },
  deleteAdmin(id) {
    return axios.delete("/api/v1/deleteUser/" + id);
  },
  fetchFAQs(data) {
    return axios.get(
      "faqs/forcms?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  getFaqsById(id) {
    return axios.get("faqs/" + id);
  },
  createFAQsTopic(data) {
    return axios.post("faqs", {question:data.question, answer:data.answer});
  },
  updateFAQsTopic(data) {
    return axios.put("faqs/" + data._id, {question:data.question, answer:data.answer});
  },
  deleteFAQsTopic(id) {
    return axios.delete("faqs/" + id);
  },
  fetchFAQsSubTopic(data) {
    return axios.get(
      "faqs/subTopicforcms?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&topicid=" +
        data.topicid +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  createFAQsSubTopic(data) {
    return axios.post("faqs/subTopic", {
      faqId: data.topicid,
      title: data.subTopic
    });
  },
  getFaqsSubTopicById(id) {
    return axios.get("faqs/subTopicById/" + id);
  },
  updateFAQsSubTopic(data) {
    return axios.post("faqs/updatesubTopicTitle", {
      subTopicId: data.subtopicsid,
      faqId: data.topicsid,
      title: data.subTopic
    });
  },
  deleteFAQsSubTopic(data) {
    return axios.post("faqs/deleteSubTopic", data);
  },
  fetchFAQsQuestions(data) {
    return axios.get(
      "faqs/questionsforcms?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&topicid=" +
        data.topicid +
        "&subtopicid=" +
        data.subtopicid +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  getQAsById(id) {
    return axios.get("faqs/qabyid/" + id);
  },
  createFAQsQAs(data) {
    return axios.post("faqs/qas", data);
  },
  updateFAQsQAs(data) {
    return axios.put("faqs/qas", data);
  },
  deleteFAQsQAs(data) {
    return axios.post("faqs/deleteqas", data);
  },

  //email module
  fetchEmails(data) {
    return axios.get(
      "/admin/emailTemplate?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchEmailDetails(id) {
    return axios.get("/admin/emailTemplate/" + id);
  },
  updateEmailDetails(data) {
    return axios.put("/admin/emailTemplate/" + data.id, data.info);
  },
  fetchTags(data) {
    return axios.get("/api/apps/email/tags");
  },
  fetchMeta(data) {
    return axios.get("/api/apps/email/meta");
  },
  moveMailsTo(payload) {
    return axios.post("/api/apps/email/move-mails", {
      emailIds: payload.emailIds,
      mailFolder: payload.to
    });
  },
  updateLabels(payload) {
    return axios.post("/api/apps/email/update-labels", {
      emailIds: payload.mails,
      label: payload.label
    });
  },
  setLabels(payload) {
    return axios.post("/api/apps/email/set-labels", {
      mailId: payload.mailId,
      labels: payload.labels
    });
  },
  setUnread(payload) {
    return axios.post("/api/apps/email/mark-unread", {
      emailIds: payload.emailIds,
      unreadFlag: payload.unreadFlag
    });
  },
  toggleIsMailStarred(payload) {
    return axios.post("/api/apps/email/set-starred", {
      mailId: payload.mailId,
      value: payload.value
    });
  },

  //make management
  fetchMakes(data) {
    return axios.get(
      "admin/make-management/getAllmake?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  makeDetails(id) {
    return axios.get("admin/make-management/makeDetail/" + id);
  },
  importMakes(data) {
    return axios.post("admin/make-management/importVehicleDataSet", data);
  },

  //market management
  fetchMarkets(data) {
    return axios.get(
      "market/allMarkets?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchMarket(marketId) {
    return axios.get(`market/${marketId}`);
  },
  updateMarketDetails(market) {
    return axios.put(`market/${market._id}`, market);
  },
  addNewMarket(market) {
    return axios.post(`market`, market);
  },
  removeMarket(id) {
    return axios.delete(`market/${id}`);
  },

  //page management
  fetchPages(data) {
    return axios.get(
      "page/?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchPageDetails(id) {
    return axios.get("page/" + id);
  },
  savePagesSettings(data) {
    return axios.post("page/createPageCss", data);
  },
  getPagesSettings(slug) {
    return axios.get("page/createPageCss?slug=" + slug);
  },
  updatePageDetails(data) {
    return axios.put("page/" + data.id, data.info);
  },
  fetchPageDetailsBySlug(slug) {
    return axios.get("page/findWithSlug?slug=" + slug);
  },

  //report management
  fetchReports(data) {
    return axios.get(
      "report?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchReport(reportId) {
    return axios.get(`report/${reportId}`);
  },

  //user management
  fetchUsers(data) {
    return axios.get(
      "admin/user-management/getallusers?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchUser(userId) {
    return axios.get(`admin/user-management/userDetail/${userId}`);
  },
  addNewUser(user) {
    return axios.post(`admin/user-management/create-user`, user);
  },
  updateAdminProfile(user) {
    return axios.post(`user/profile`, user);
  },
  updateUserDetails(user) {
    return axios.put(`admin/user-management/userDetail/${user._id}`, user);
  },
  removeUser(id) {
    return axios.delete(`admin/user-management/userDetail/${id}`);
  },

  //car dealers
  fetchCarDealers(data) {
    return axios.get(
      "admin/carDealer/getAll?page=" +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  fetchCarDealer(carDealerId) {
    return axios.get(`admin/carDealer/detail/${carDealerId}`);
  },
  addNewCarDealer(dealer) {
    return axios.post(`admin/carDealer/create`, dealer);
  },
  updateCarDealerDetails(data) {
    return axios.put(`admin/carDealer/detail/${data._id}`, data);
  },
  removeCarDealer(id) {
    return axios.delete(`admin/carDealer/detail/${id}`);
  },
  addNewCar(data) {
    return axios.post(`admin/carDealer/${data.dealerId}/addCar`, data);
  },
  fetchDealerCars(data) {
    return axios.get(
      `admin/carDealer/${data.id}/cars?page=` +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  deleteDealerCar(id) {
    return axios.delete(`admin/carDealer/car/${id}`);
  },
  updateDealerCar(data) {
    return axios.post(`admin/carDealer/car/${data._id}`, data);
  },
  //car attributes apis
  getCarMakerList(data) {
    return axios.get(
      `carAttributes/maker?after=` +
        data.after +
        "&limit=" +
        data.limit +
        "&searchText=" +
        data.search
    );
  },
  getCarModels(data) {
    return axios.get(
      `carAttributes/carModel?after=` +
        data.after +
        "&limit=" +
        data.limit +
        "&searchText=" +
        data.search +
        "&make=" +
        data.make
    );
  },
  getCarYearList(data) {
    return axios.get(
      `carAttributes/year?after=` +
        data.after +
        "&limit=" +
        data.limit +
        "&searchText=" +
        data.search +
        "&make=" +
        data.make +
        "&model=" +
        data.model
    );
  },
  getCarBadgeList(data) {
    return axios.get(
      `carAttributes/badge?after=` +
        data.after +
        "&limit=" +
        data.limit +
        "&searchText=" +
        data.search +
        "&make=" +
        data.make +
        "&model=" +
        data.model +
        "&year=" +
        data.year
    );
  },
  getCarSeriesList(data) {
    return axios.get(
      `carAttributes/series?after=` +
        data.after +
        "&limit=" +
        data.limit +
        "&searchText=" +
        data.search +
        "&make=" +
        data.make +
        "&year=" +
        data.year +
        "&model=" +
        data.model +
        "&badge=" +
        data.badge
    );
  },
  getCarBodyType(data) {
    return axios.get(
      `carAttributes/bodyType?after=` +
        data.after +
        "&limit=" +
        data.limit +
        "&searchText=" +
        data.search +
        "&make=" +
        data.make +
        "&model=" +
        data.model +
        "&year=" +
        data.year +
        "&badge=" +
        data.badge +
        "&series=" +
        data.series +
        "&isBodyTypeManual=" +
        data.isBodyTypeManual
    );
  },
  getCarTransmission(data) {
    return axios.get(
      `carAttributes/transmission?after=` +
        data.after +
        "&limit=" +
        data.limit +
        "&searchText=" +
        data.search +
        "&make=" +
        data.make +
        "&model=" +
        data.model +
        "&year=" +
        data.year +
        "&badge=" +
        data.badge +
        "&series=" +
        data.series +
        "&bodyType=" +
        data.bodyType +
        "&isTransmissionManual=" +
        data.isTransmissionManual
    );
  },
  getCarEngineCapacity(data) {
    return axios.get(
      `carAttributes/engineCapacity?after=` +
        data.after +
        "&limit=" +
        data.limit +
        "&searchText=" +
        data.search +
        "&make=" +
        data.make +
        "&model=" +
        data.model +
        "&year=" +
        data.year +
        "&badge=" +
        data.badge +
        "&series=" +
        data.series +
        "&bodyType=" +
        data.bodyType +
        "&transmission=" +
        data.transmission +
        "&isEngineCapacityManual=" +
        data.isEngineCapacityManual
    );
  },
  getCarCylinders(data) {
    return axios.get(
      `carAttributes/cylinders?after=` +
        data.after +
        "&limit=" +
        data.limit +
        "&searchText=" +
        data.search +
        "&make=" +
        data.make +
        "&model=" +
        data.model +
        "&year=" +
        data.year +
        "&badge=" +
        data.badge +
        "&series=" +
        data.series +
        "&bodyType=" +
        data.bodyType +
        "&transmission=" +
        data.transmission +
        "&engineCapacity=" +
        data.engineCapacity +
        "&isCylinderManual=" +
        data.isCylinderManual
    );
  },
  getStatesList(data) {
    return axios.get(
      `carAttributes/state?page=` + data.page + "&limit=" + data.limit
    );
  },
  getPricesRange() {
    return axios.get(`carAttributes/priceRange`);
  },
  getKilometerRange() {
    return axios.get(`carAttributes/kiloMeterRange`);
  },
  //log of lead
  getLogList(data) {
    return axios.get(
      `admin/logLead/getAll?page=` +
        data.page +
        "&limit=" +
        data.limit +
        "&search=" +
        data.search +
        "&sort=" +
        data.sort +
        "&dir=" +
        data.dir
    );
  },
  getLogDetails(id) {
    return axios.get(`admin/logLead/${id}`);
  },
  removeLog(id) {
    return axios.delete(`admin/logLead/${id}`);
  }
};
