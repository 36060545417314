/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit("UPDATE_VERTICAL_NAV_MENU_WIDTH", width);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit("UPDATE_STARRED_PAGE", payload);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_LIMITED", list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_MORE", list);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({ commit }, val) {
    commit("UPDATE_THEME", val);
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({ commit }, payload) {
    commit("UPDATE_USER_INFO", payload);
  },
  updateUserRole({ dispatch }, payload) {
    // Change client side
    payload.aclChangeRole(payload.userRole);

    // Make API call to server for changing role

    // Change userInfo in localStorage and store
    dispatch("updateUserInfo", { userRole: payload.userRole });
  },

  //update each page latest pagination
  updateVehiclePage({ commit }, payload) {
    commit("UPDATE_VEHICLE_PAGE", payload);
  },
  updateVehiclePageLimit({ commit }, payload) {
    commit("UPDATE_VEHICLE_LIMIT", payload);
  },
  updateVehiclePageFilter({ commit }, payload) {
    commit("UPDATE_VEHICLE_FILTER", payload);
  },
  updateVehiclePageSearch({ commit }, payload) {
    commit("UPDATE_VEHICLE_SEARCH", payload);
  },
  updateDealerPage({ commit }, payload) {
    commit("UPDATE_DEALER_PAGE", payload);
  },
  updateDealerPageLimit({ commit }, payload) {
    commit("UPDATE_DEALER_LIMIT", payload);
  },
  updateDealerPageSearch({ commit }, payload) {
    commit("UPDATE_DEALER_SEARCH", payload);
  },
  updateLogPage({ commit }, payload) {
    commit("UPDATE_LOG_PAGE", payload);
  },
  updateLogPageLimit({ commit }, payload) {
    commit("UPDATE_LOG_LIMIT", payload);
  },
  updateLogPageSearch({ commit }, payload) {
    commit("UPDATE_LOG_SEARCH", payload);
  },
  updateReportPage({ commit }, payload) {
    commit("UPDATE_REPORT_PAGE", payload);
  },
  updateReportPageLimit({ commit }, payload) {
    commit("UPDATE_REPORT_LIMIT", payload);
  },
  updateDealerVehiclePage({ commit }, payload) {
    commit("UPDATE_DEALER_VEHICLE_PAGE", payload);
  },
  updateDealerVehiclePageLimit({ commit }, payload) {
    commit("UPDATE_DEALER_VEHICLE_LIMIT", payload);
  },
  updateDealerVehiclePageSearch({ commit }, payload) {
    commit("UPDATE_DEALER_VEHICLE_SEARCH", payload);
  },
  initToFirstPage({ commit }, payload) {
    commit("INIT_PAGES_TO_FIRST_PAGE", payload);
  }
};

export default actions
