import jwt from "../../http/requests/auth/jwt/index.js";

export default {
  // Fetch all logs of lead
  fetchLogs({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getLogList(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // Fetch log details
  fetchLogDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getLogDetails(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  
  deleteLog({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .removeLog(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};