/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
// import authService from "./auth/authService";
import store from "./store/store";
import { auth } from "./auth/auth";
import "firebase/auth";

Vue.use(Router);
let subscriptionUser = '';
const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/admin",
      name: "Admin",
      meta: {
        rule: "editor"
      },
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            pageTitle: "Login",
            rule: "editor"
          }
        }
      ]
      // =============================================================================
      // PAGES
      // =============================================================================
    },
    {
      path: "/admin",
      redirect: "/admin/dashboard",
      name: "Admin",
      meta: {
        rule: "admin"
      },
      component: () => import("./layouts/main/Main.vue"),
      children: [
        {
          path: "dashboard",
          name: "Home",

          component: () => import("./views/Dashboard.vue"),
          meta: {
            pageTitle: "Dashboard",
            rule: "admin"
            // 'authRequired': true
          }
        },

        {
          path: "cms-pages",
          name: "CMSPages",
          component: () => import("./views/cms-pages/CMSPage.vue"),
          meta: {
            pageTitle: "Pages",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "cms-pages/:id",
          name: "CMSPageDetails",
          component: () => import("./views/cms-pages/CMSPageDetails.vue"),
          meta: {
            pageTitle: "Page Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Pages", url: "/admin/cms-pages" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "user-management",
          name: "Users",
          component: () => import("./views/user-management/users.vue"),
          meta: {
            pageTitle: "Users",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Users", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "user-management/newuser",
          name: "AddUserDetail",
          component: () => import("./views/user-management/userForm.vue"),
          meta: {
            pageTitle: "Add User",
            breadcrumb: [
              { title: "Home" },
              { title: "Users", url: "/admin/user-management" },
              { title: "New", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "user-management/view/:id",
          name: "UserDetail",
          component: () => import("./views/user-management/userDetails.vue"),
          meta: {
            pageTitle: "User Profile",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Users", url: "/admin/user-management" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "user-management/user/:userid/subscription/:id/jsonview",
          name: "userjsonView",
          component: () => import("./views/subs-management/jsonView.vue"),
          meta: {
            pageTitle: "User Subscriptions",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Users", url: "/admin/user-management" },
              { title: "View", active: true },
              { title: "Json View ", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "user-management/edit/:id",
          name: "EditUserDetail",
          component: () => import("./views/user-management/userForm.vue"),
          meta: {
            pageTitle: "User Edit",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Users", url: "/admin/user-management" },
              { title: "Edit", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },

        //car dealer routes
        {
          path: "car-dealer",
          name: "Car Dealers",
          component: () => import("./views/car-dealer/carDealerList.vue"),
          meta: {
            pageTitle: "Car Dealers",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Car Dealers", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "car-dealer/newdealer",
          name: "AddCarDealer",
          component: () => import("./views/car-dealer/addCarDealer.vue"),
          meta: {
            pageTitle: "Add Car Dealer",
            breadcrumb: [
              { title: "Home" },
              { title: "Dealers", url: "/admin/car-dealer" },
              { title: "Car Dealer", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "car-dealer/:id",
          name: "CarDealerDetails",
          component: () => import("./views/car-dealer/dealerDetails.vue"),
          meta: {
            pageTitle: "Car Dealer Details",
            breadcrumb: [
              { title: "Home" },
              { title: "Dealers", url: "/admin/car-dealer" },
              { title: "Dealer Details", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "car-dealer/:id/addCar",
          name: "AddNewCar",
          component: () => import("./views/car-dealer/addNewCar.vue"),
          meta: {
            pageTitle: "Add New Car",
            breadcrumb: [
              { title: "Home" },
              { title: "Dealers", url: "/admin/car-dealer" },
              { title: "New Car", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "car-dealer/:carId/editCar",
          name: "EditCar",
          component: () => import("./views/car-dealer/editCar.vue"),
          meta: {
            pageTitle: "Update Car",
            breadcrumb: [
              { title: "Home" },
              { title: "Dealers", url: "/admin/car-dealer" },
              { title: "Update Car", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },

        {
          path: "vehicle/:carId/editCar",
          name: "EditVehicle",
          component: () => import("./views/car-dealer/editCar.vue"),
          meta: {
            pageTitle: "Update Car",
            breadcrumb: [
              { title: "Home" },
              { title: "Vehicle", url: "/admin/vehicle/pending" },
              { title: "Update Car", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },

        {
          path: "log-lead",
          name: "LogLead",
          component: () => import("./views/log-lead/listLogLead.vue"),
          meta: {
            pageTitle: "Log of Leads",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Logs", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },

        {
          path: "log-lead/:id",
          name: "LogLeadDetails",
          component: () => import("./views/log-lead/logLeadDetails.vue"),
          meta: {
            pageTitle: "Logs Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Logs", url: "/admin/log-lead" },
              { title: "Details", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "make-management",
          name: "Make",
          component: () => import("./views/make-management/makeList.vue"),
          meta: {
            pageTitle: "Make",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Make", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "make-management/:id",
          name: "MakeDetails",
          component: () => import("./views/make-management/makeDetails.vue"),
          meta: {
            pageTitle: "Make Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Make", url: "/admin/make-management" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "settings",
          name: "Settings",
          component: () => import("./views/settings/settingsList.vue"),
          meta: {
            pageTitle: "Settings",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Settings", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "report",
          name: "Reports",
          component: () => import("./views/report/reports.vue"),
          meta: {
            pageTitle: "Reports",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Reports", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "report/:id",
          name: "ReportDetail",
          component: () => import("./views/report/reportDetails.vue"),
          meta: {
            pageTitle: "Report Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Reports", url: "/admin/report" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "faqs",
          name: "FAQs",
          component: () => import("./views/faqs/FaqsList.vue"),
          meta: {
            pageTitle: "FAQs",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "FAQs", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "faqs/new",
          name: "FAQsNew",
          component: () => import("./views/faqs/Question.vue"),
          meta: {
            pageTitle: "Add New FAQ",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "FAQs", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "faqs/edittopic/:id",
          name: "FAQsEdit",
          component: () => import("./views/faqs/Question.vue"),
          meta: {
            pageTitle: "FAQ Edit",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "FAQs Edit", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "faqs/subTopic/:id",
          name: "SubTopicList",
          component: () => import("./views/faqs/SubTopicList.vue"),
          meta: {
            pageTitle: "FAQs Sub Topics",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "FAQs Sub Topics", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "faqs/sub/:id/new",
          name: "FAQsSubTopic",
          component: () => import("./views/faqs/SubTopic.vue"),
          meta: {
            pageTitle: "FAQs Sub Topic New",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "FAQs Sub Topic New", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "faqs/sub/:id/edit/:subid",
          name: "FAQsSubTopicEdit",
          component: () => import("./views/faqs/SubTopic.vue"),
          meta: {
            pageTitle: "FAQs Sub Topic Edit",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "FAQs Sub Topic Edit", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        //qa
        {
          path: "faqs/topics/:id/subtopics/:sid",
          name: "QAList",
          component: () => import("./views/faqs/QuestionsList.vue"),
          meta: {
            pageTitle: "QA List",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "QA List", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "faqs/topics/:id/subtopics/:sid/qa/new",
          name: "QANew",
          component: () => import("./views/faqs/Question.vue"),
          meta: {
            pageTitle: "QA New",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "QA New", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "faqs/topics/:id/subtopics/:sid/qaedit/:qid",
          name: "QAEdit",
          component: () => import("./views/faqs/Question.vue"),
          meta: {
            pageTitle: "QA Edit",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "QA Edit", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "auction/details/:id",
          name: "AuctionDetails",
          component: () => import("./views/auction/auctionDetails.vue"),
          meta: {
            pageTitle: "Auction Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Auction", url: "/admin/auction/live" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "auction/live",
          name: "Auctions",
          component: () => import("./views/auction/liveList.vue"),
          meta: {
            pageTitle: "Live Auctions",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Auctions", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "auction/completed",
          name: "Auctions",
          component: () => import("./views/auction/completedList.vue"),
          meta: {
            pageTitle: "Completed Auctions",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Auctions", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "vehicle",
          name: "Vehicles",
          component: () => import("./views/car-list/carList.vue"),
          meta: {
            pageTitle: "Vehicles",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Vehicles", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "vehicle/approved",
          name: "Vehicles",
          component: () => import("./views/car-list/carList.vue"),
          meta: {
            pageTitle: "Approved Vehicles",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Vehicles", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "vehicle/pending",
          name: "Vehicles",
          component: () => import("./views/car-list/carPendingList.vue"),
          meta: {
            pageTitle: "Pending Vehicles",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Vehicles", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "vehicle/auction-completed",
          name: "Vehicles",
          component: () => import("./views/car-list/carAuctionCompletedList.vue"),
          meta: {
            pageTitle: "Auction Completed Vehicles",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Vehicles", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "vehicle/rejected",
          name: "Vehicles",
          component: () => import("./views/car-list/rejectedCarList.vue"),
          meta: {
            pageTitle: "Rejected Vehicles Listing",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Rejected Vehicles", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "vehicle/:id",
          name: "VehicleDetails",
          component: () => import("./views/car-list/carDetails.vue"),
          meta: {
            pageTitle: "Vehicle Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Vehicles", url: "/admin/vehicle/pending" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "vehicle/:id",
          name: "VehicleDetailsApproved",
          component: () => import("./views/car-list/carDetails.vue"),
          meta: {
            pageTitle: "Vehicle Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Vehicles", url: "/admin/vehicle/approved" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "vehicle/:id",
          name: "VehicleDetailsAuctionApproved",
          component: () => import("./views/car-list/carDetails.vue"),
          meta: {
            pageTitle: "Vehicle Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Vehicles", url: "/admin/vehicle/auction-completed" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "vehicle-rejected/:id",
          name: "RejectedVehicleDetails",
          component: () => import("./views/car-list/carDetails.vue"),
          meta: {
            pageTitle: "Vehicle Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Rejected Vehicles", url: "/admin/vehicle/rejected" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "market",
          name: "Markets",
          component: () => import("./views/market/marketList.vue"),
          meta: {
            pageTitle: "Markets",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Markets", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "market/new",
          name: "AddMarketDetails",
          component: () => import("./views/market/marketAdd.vue"),
          meta: {
            pageTitle: "New Market",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Market", url: "/admin/market" },
              { title: "New", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "market/view/:id",
          name: "MarketDetails",
          component: () => import("./views/market/marketView.vue"),
          meta: {
            pageTitle: "Market Details",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Market", url: "/admin/market" },
              { title: "View", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "market/edit/:id",
          name: "EditMarketDetails",
          component: () => import("./views/market/marketEdit.vue"),
          meta: {
            pageTitle: "Market Edit",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Market", url: "/admin/market" },
              { title: "Edit", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "subs-management",
          name: "Subscribers",
          component: () => import("./views/subs-management/subs.vue"),
          meta: {
            pageTitle: "Subscribers",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Subscribers", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "subs-management/:id/jsonview",
          name: "jsonView",
          component: () => import("./views/subs-management/jsonView.vue"),
          meta: {
            pageTitle: "Subscriptions",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Subscribers", url: "/admin/subs-management" },
              { title: "Subscriptions Details ", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "/admin/profile",
          name: "admin-profile",
          component: () => import("@/views/pages/adminProfile.vue"),
          meta: {
            rule: "admin"
          }
        },
        {
          path: "/admin/my-details",
          name: "my-details",
          component: () => import("@/views/cms-pages/MyDetails.vue"),
          meta: {
            rule: "admin"
          }
        },
        {
          path: "/admin/email-template",
          name: "email-template",
          component: () => import("@/views/cms-pages/email/EmailTemplate.vue"),
          meta: {
            pageTitle: "Email Templates",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Templates", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        },
        {
          path: "/admin/email-template/:id",
          name: "edit-email-template",
          component: () =>
            import("./views/cms-pages/email/EditEmailTemplate.vue"),
          meta: {
            pageTitle: "Edit Templates",
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Templates", url: "/admin/email-template" },
              { title: "Edit", active: true }
            ],
            rule: "admin"
            //'authRequired': true
          }
        }
      ]
    },
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/admin/login"
        },
        {
          path: "/change-password",
          name: "change-password",
          component: () => import("@/views/pages/changePassword.vue"),
          meta: {
            rule: "editor"
          }
        }
      ]
    },

    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/callback",
          name: "auth-callback",
          component: () => import("@/views/Callback.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/admin/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "editor"
          }
        },
        // {
        //   path: "/pages/register",
        //   name: "page-register",
        //   component: () =>
        //     import ("@/views/pages/register/Register.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        {
          path: "/forgot-password",
          name: "page-forgot-password",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/set-password/:token",
          name: "user-forgot-password",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token
            };
            store
              .dispatch("auth/checkTokenExpiry", payload)
              .then(() => {
                next();
              })
              .catch(error => {
                next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/setPassword.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/verify-email/:token",
          name: "user-account-verification",
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token
            };
            store
              .dispatch("auth/verifyMail", payload)
              .then(() => {
                next();
                //next("/admin/login");
              })
              .catch(error => {
                next("/pages/error-419");
              });
          },
          component: () => import("@/views/pages/verificationSuccess.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/reset-password/:token",
          name: "user-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "editor"
          },
          beforeEnter: (to, from, next) => {
            const payload = {
              token: to.params.token
            };
            store
              .dispatch("auth/isTokenExpired", payload)
              .then(() => {
                next();
              })
              .catch(error => {
                next("/pages/error-419");
              });
          }
        },
        {
          path: "/success",
          name: "reset-password-success",
          component: () => import("@/views/pages/Success.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "editor"
          }
        },

        // {
        //   path: "/pages/comingsoon",
        //   name: "page-coming-soon",
        //   component: () =>
        //     import ("@/views/pages/ComingSoon.vue"),
        //   meta: {
        //     rule: "editor"
        //   }
        // },
        {
          path: "/pages/error-419",
          name: "page-error-419",
          component: () => import("@/views/pages/Error419.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/privacy-policy/:slug",
          name: "PrivacyPolicy",
          component: () => import("@/views/pages/PrivacyPolicy.vue"),
          meta: {
            rule: "editor"
          }
        },
        {
          path: "/pages/terms-and-conditions/:slug",
          name: "TermsAndConditions",
          component: () => import("@/views/pages/Terms.vue"),
          meta: {
            rule: "editor"
          }
        }
      ]
    }
    // Redirect to 404 page, if no match found
  ]
});

router.afterEach(to => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
  document.title = to.meta.pageTitle || "Caroo";



});


router.beforeEach((to, from, next) => {

  auth(to, from, next);
  // return next(); //handeled on above fn
});

export default router;
