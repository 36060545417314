/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val;
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val;
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width;
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList["pages"].data.findIndex(
      item => item.url == payload.url
    );

    // update the main list
    state.navbarSearchAndPinList["pages"].data[index].is_bookmarked =
      payload.val;

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(
        state.navbarSearchAndPinList["pages"].data[index]
      );
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex(
        item => item.url == payload.url
      );

      // remove item using index
      state.starredPages.splice(index, 1);
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false;
    let lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map(i => {
      if (list.indexOf(i) > -1) downToUp = true;
    });

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val;
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    let userInfo =
      JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ) || state.AppActiveUser;

    if (payload) {
      payload.uid = payload._id;
      payload.displayName = payload.firstName + " " + payload.lastName;
      payload.photoURL =
        payload.profileImage || require("@/assets/images/profile/default.png");
      payload.status = "online";
      payload.userRole = payload.userType;

      state.AppActiveUser = payload;
    }

    // for (const property of Object.keys(payload)) {
    //   if (payload[property] != null) {
    //     // If some of user property is null - user default property defined in state.AppActiveUser
    //     state.AppActiveUser = payload[property];

    //     // Update key in localStorage
    //     userInfo[property] = payload[property];
    //   }
    // }
    // Store data in localStorage
    localStorage.setItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}UserInfo`,
      JSON.stringify(userInfo)
    );
  },

  //mutation for pagination page
  UPDATE_VEHICLE_PAGE(state, payload) {
    state.localPages.vehiclePage = payload;
  },
  UPDATE_VEHICLE_LIMIT(state, payload) {
    state.localPages.vehiclePageLimit = payload;
  },
  UPDATE_VEHICLE_FILTER(state, payload) {
    state.localPages.vehiclePageFilter = payload;
  },
  UPDATE_VEHICLE_SEARCH(state, payload) {
    state.localPages.vehiclePageSearch = payload;
  },
  UPDATE_LOG_PAGE(state, payload) {
    state.localPages.logPage = payload;
  },
  UPDATE_LOG_LIMIT(state, payload) {
    state.localPages.logPageLimit = payload;
  },
  UPDATE_LOG_SEARCH(state, payload) {
    state.localPages.logPageSearch = payload;
  },
  UPDATE_DEALER_PAGE(state, payload) {
    state.localPages.dealerPage = payload;
  },
  UPDATE_DEALER_LIMIT(state, payload) {
    state.localPages.dealerPageLimit = payload;
  },
  UPDATE_DEALER_SEARCH(state, payload) {
    state.localPages.dealerPageSearch = payload;
  },
  UPDATE_DEALER_VEHICLE_PAGE(state, payload) {
    state.localPages.dealerVehiclePage = payload;
  },
  UPDATE_DEALER_VEHICLE_LIMIT(state, payload) {
    state.localPages.dealerVehiclePageLimit = payload;
  },
  UPDATE_DEALER_VEHICLE_SEARCH(state, payload) {
    state.localPages.dealerVehiclePageSearch = payload;
  },
  UPDATE_REPORT_PAGE(state, payload) {
    state.localPages.reportPage = payload;
  },
  UPDATE_REPORT_LIMIT(state, payload) {
    state.localPages.reportPageLimit = payload;
  },
  INIT_PAGES_TO_FIRST_PAGE(state, payload) {
    switch (payload.pageType) {
      case "vehicle":
        state.localPages.logPage = 1;
        state.localPages.logPageLimit = 10;
        state.localPages.logPageSearch = "";
        state.localPages.dealerPage = 1;
        state.localPages.dealerPageLimit = 10;
        state.localPages.dealerPageSearch = "";
        state.localPages.dealerVehiclePage = 1;
        state.localPages.dealerVehiclePageLimit = 10;
        state.localPages.dealerVehiclePageSearch = "";  
        state.localPages.reportPage = 1;
        state.localPages.reportPageLimit = 10;
        break;
      case "log":
        state.localPages.vehiclePage = 1;
        state.localPages.vehiclePageLimit = 10;
        state.localPages.vehiclePageFilter = "All";
        state.localPages.vehiclePageSearch = "";
        state.localPages.dealerPage = 1;
        state.localPages.dealerPageLimit = 10;
        state.localPages.dealerPageSearch = "";
        state.localPages.dealerVehiclePage = 1;
        state.localPages.dealerVehiclePageLimit = 10;
        state.localPages.dealerVehiclePageSearch = "";  
        state.localPages.reportPage = 1;
        state.localPages.reportPageLimit = 10;
        break;
      case "report":
        state.localPages.logPage = 1;
        state.localPages.logPageLimit = 10;
        state.localPages.logPageSearch = "";
        state.localPages.dealerPage = 1;
        state.localPages.dealerPageLimit = 10;
        state.localPages.dealerPageSearch = "";
        state.localPages.dealerVehiclePage = 1;
        state.localPages.dealerVehiclePageLimit = 10;
        state.localPages.vehiclePageFilter = "All";
        state.localPages.vehiclePageSearch = "";
        state.localPages.vehiclePage = 1;
        state.localPages.vehiclePageLimit = 10;
        state.localPages.dealerVehiclePageSearch = "";  
        break;
      case "dealer":
        state.localPages.logPage = 1;
        state.localPages.logPageLimit = 10;
        state.localPages.logPageSearch = "";
        state.localPages.vehiclePage = 1;
        state.localPages.vehiclePageLimit = 10;
        state.localPages.vehiclePageFilter = "All";
        state.localPages.vehiclePageSearch = "";
        state.localPages.reportPage = 1;
        state.localPages.reportPageLimit = 10;
        break;
      case "dealerCar":
        state.localPages.logPage = 1;
        state.localPages.logPageLimit = 10;
        state.localPages.logPageSearch = "";
        state.localPages.vehiclePage = 1;
        state.localPages.vehiclePageLimit = 10;
        state.localPages.vehiclePageFilter = "All";
        state.localPages.vehiclePageSearch = "";
        state.localPages.reportPage = 1;
        state.localPages.reportPageLimit = 10;
        break;
      default:
        state.localPages.vehiclePage = 1;
        state.localPages.vehiclePageLimit = 10;
        state.localPages.vehiclePageFilter = "All";
        state.localPages.vehiclePageSearch = "";
        state.localPages.logPage = 1;
        state.localPages.logPageLimit = 10;
        state.localPages.logPageSearch = "";
        state.localPages.dealerPage = 1;
        state.localPages.dealerPageLimit = 10;
        state.localPages.dealerPageSearch = "";
        state.localPages.dealerVehiclePage = 1;
        state.localPages.dealerVehiclePageLimit = 10;  
        state.localPages.dealerVehiclePageSearch = "";  
        state.localPages.reportPage = 1;
        state.localPages.reportPageLimit = 10;
    }
  }
};

export default mutations;
