/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// import axios from "@/axios.js"
import jwt from "../../http/requests/auth/jwt/index.js";

export default {
    fetchReports({ commit }, data) {
        return new Promise((resolve, reject) => {
            jwt.fetchReports(data)

                .then((res) => {
                    resolve(res);
                }).catch((err) => {
                    reject(err)
                });
        })
    },
    fetchReport(context, reportId) {
        return new Promise((resolve, reject) => {
            jwt.fetchReport(reportId)
           
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}
