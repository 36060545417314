// import state from "./moduleEmailState.js";
// import mutations from "./moduleEmailMutations.js";
import actions from "./moduleLogLeadActions.js";
// import getters from "./moduleEmailGetters.js";

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: actions,
  getters: {}
};
