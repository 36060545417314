import axios from "@/axios";
import jwt from "../../http/requests/auth/jwt/index.js";
export default {
  fetchSubscribers({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchSubscribers(data)
        // axios.get('admin/subscribers/getAllSubscriber?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchMySubscriptions({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .userSubscripiton(
          data.userId,
          data.page,
          data.limit,
          data.search,
          data.sort,
          data.dir
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchIosJsonSubscriptionDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .subscrptionjsonview(data)
        //axios.post('/admin/subscribers/jsonView', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  restoreSubscription({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .restore(data.subscriptionId)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //auction list
  fetchAuctions({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchAuctionsList(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  auctionDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .auctionDetail(id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //vehicles list
  fetchVehicles({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchVehicles(data)
        //axios.get('admin/vehicle/getUserCarList?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //for csv
  fetchVehiclesForCsv({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchVehiclesForCsv(data)
        //axios.get('admin/vehicle/getUserCarListCsv?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // car details
  carDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .carDetail(id)
        //axios.get('car/' + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // car delete
  deleteCar({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteCar(id)
        //axios.delete('admin/vehicle/deleteUserCarById/' + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //dashboard
  fetchCardDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchCardDetails()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  handleNewCarListingStatus({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .acceptRejectNewCarListing(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  handleCarBiddingHistory({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getCarBiddingHistory(id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  handleBidAmountChange({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .changeBidAmount(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  handleCheckPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .checkPassword(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //dashboard
  fetchChartsDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchChartsDetails()
        // axios.get('admin/dashboard/getChartDetails')
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //settings
  //vehicles list
  getSettingList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .getSettingList(data)
        //axios.get('admin/settings/getSettingsList?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  updateSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .upateSetting(data)
        //axios.put('admin/settings/setting/' + data._id, data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchAdmins({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      jwt
        .fetchAdmins(data)
        //axios.get('/api/v1/admins/adminDetails?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchAdminDetails({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchAdminDetails(id)
        //axios.get('/api/v1/admins/adminDetails/' + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateAdminDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateAdminDetails(data)
        //axios.post('/api/v1/admins/adminDetails/update/' + data.id, data.info)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  saveAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveAdmin(data)
        //axios.post('/api/v1/register', data.info)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteAdmin({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteAdmin(id)
        //axios.delete('/api/v1/deleteUser/' + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFAQs({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchFAQs(data)
        //axios.get('faqs/forcms?page=' + data.page + '&limit=' + data.limit + '&search=' + data.search + '&sort=' + data.sort + '&dir=' + data.dir)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getFaqsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getFaqsById(id)
        //axios.get('faqs/' + id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createFAQsTopic({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createFAQsTopic(data)

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateFAQsTopic({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateFAQsTopic(data)

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteFAQsTopic({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteFAQsTopic(id)

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //sub topic
  fetchFAQsSubTopic({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchFAQsSubTopic(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  createFAQsSubTopic({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createFAQsSubTopic(data)

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getFaqsSubTopicById({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getFaqsSubTopicById(id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateFAQsSubTopic({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateFAQsSubTopic(data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteFAQsSubTopic({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteFAQsSubTopic(data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //questions
  fetchFAQsQuestions({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchFAQsQuestions(data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getQAsById({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .getQAsById(id)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createFAQsQAs({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createFAQsQAs(data)

        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateFAQsQAs({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateFAQsQAs(data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  deleteFAQsQAs({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteFAQsQAs(data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
