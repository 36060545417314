/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {

	// COMPONENT
		// vx-autosuggest
	// starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {

    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return "xl"
    else if (state.windowWidth >= 992) return "lg"
    else if (state.windowWidth >= 768) return "md"
    else if (state.windowWidth >= 576) return "sm"
    else return "xs"
  },
  vehiclePage: state => {
    return state.localPages.vehiclePage
  },
  vehiclePageLimit: state => {
    return state.localPages.vehiclePageLimit
  },
  vehiclePageFilter: state => {
    return state.localPages.vehiclePageFilter
  },
  vehiclePageSearch: state => {
    return state.localPages.vehiclePageSearch
  },
  logPage: state => {
    return state.localPages.logPage
  },
  logPageLimit: state => {
    return state.localPages.logPageLimit
  },
  logPageSearch: state => {
    return state.localPages.logPageSearch
  },
  dealerPage: state => {
    return state.localPages.dealerPage
  },
  dealerPageLimit: state => {
    return state.localPages.dealerPageLimit
  },
  dealerPageSearch: state => {
    return state.localPages.dealerPageSearch
  },
  reportPage: state => {
    return state.localPages.reportPage
  },
  reportPageLimit: state => {
    return state.localPages.reportPageLimit
  },
  dealerVehiclePage: state => {
    return state.localPages.dealerVehiclePage
  },
  dealerVehiclePageLimit: state => {
    return state.localPages.dealerVehiclePageLimit
  },
  dealerVehiclePageSearch: state => {
    return state.localPages.dealerVehiclePageSearch
  },
}

export default getters
